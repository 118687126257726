

/* Screens Resolution : 992px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 1200px) {

}

/* Screens Resolution : 992px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 992px) {
	
	

}


/* Screens Resolution : 767px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 767px) {
	
	/* ---------------------------------
	1. PRIMARY STYLES
	--------------------------------- */

	p{ line-height: 1.4; }

	h1{ font-size: 2.8em; line-height: 1; }
	h2{ font-size: 2.2em; line-height: 1.1; }
	h3{ font-size: 1.8em; }
	
	
	/* ---------------------------------
	3. MAIN SECTION
	--------------------------------- */
	
	.main-area-wrapper{ padding: 0px; }
	
	
	.main-area{ padding-bottom: 80px; }
	
	.main-area .email-input-area{ margin: 5% auto; width: 90%; height: 47px; }
	
	.main-area .email-input-area .email-input{ width: 100%; position: absolute; top: 0; bottom: 0; left: 0; 
	border-radius: 40px; border: 0; outline: 0; padding: 0 95px 0 15px; }

	.main-area .email-input-area .submit-btn{ width: 80px; text-align: center; position: absolute; top: 5px; bottom: 5px; right: 5px;  }
}

/* Screens Resolution : 479px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 479px) {

	/* ---------------------------------
	1. PRIMARY STYLES
	--------------------------------- */

	body{ font-size: 12px; }
	
	/* ---------------------------------
	3. MAIN SECTION
	--------------------------------- */
}

/* Screens Resolution : 359px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 359px) {
	
	
}

/* Screens Resolution : 290px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 290px) {
	
	
}